import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, date, number, object, string } from "yup";
import { DFlex } from "./GenericFlex";
import {
	GenericForm,
	IDateTime,
	IMultiSelect,
	INumber,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { DGrid } from "./GenericGrid";
import { NewLegType, legKindOf, noInvalidDate, noNaN } from "./helpers";

const fields = {
	id: number().transform(noNaN).label("ID"),
	jobId: number().transform(noNaN).label("Job"),
	legType: number().required().label("Type"),
	goodsIds: array().of(number().required()).required().min(1).label("Goods"),
	notes: string().label("Notes"),
	supplierInvoiceNumber: string().label("Supplier Invoice Number"),
	supplierInvoice: number().label("Supplier Invoice Status"),
	supplierInvoiceDate: date()
		.transform(noInvalidDate)
		.label("Supplier Invoice Date"),
	cost: number().transform(noNaN).label("Cost"),
	currencyId: string().label("Currency"),
	/* operation, ferry, customs */
	driverId: number().label("Driver"),
	truckId: number().label("Truck"),
	trailerId: number().label("Trailer"),
	subcontractorId: number().label("Subcontractor"),
	transferBusinessUnitId: number().label("Business Unit"),
	/* operation */
	truckTypeId: number().label("Truck Type"),
	trailerTypeId: number().label("Trailer Type"),
	collectionLocationId: number().label("Collection Location"),
	collectionDate: date().transform(noInvalidDate).label("Collection Date"),
	deliveryLocationId: number().label("Delivery Location"),
	deliveryDate: date().transform(noInvalidDate).label("Delivery Date"),
	/* storage */
	storageLocation: number().label("Storage Location"),
	storageStartDate: date().transform(noInvalidDate).label("Storage Start Date"),
	storageEndDate: date().transform(noInvalidDate).label("Storage End Date"),
	/* ferry */
	ferryRoute: string().label("Ferry Route"),
	ferrySailingDate: date().transform(noInvalidDate).label("Ferry Sailing Date"),
	ferryReferenceNumber: string().label("Ferry Reference Number"),
	/* customs */
	ucr: string().label("UCR"),
	mrn: string().label("MRN"),
	t1: string().label("T1"),
	clearanceLocation: string().label("Clearance Location"),
	clearanceDate: date().transform(noInvalidDate).label("Clearance Date"),
};

const useLegFormSchema = (defaultType?: number) => {
	const [type, setType] = useState<number | undefined>(defaultType);
	let schema = object(fields);
	if (type === NewLegType.Collection || type === NewLegType.CollectDeliver) {
		schema = schema.shape({
			collectionLocationId: fields.collectionLocationId.required(),
			collectionDate: fields.collectionDate.required(),
		});
	}
	if (type === NewLegType.Delivery || type === NewLegType.CollectDeliver) {
		schema = schema.shape({
			deliveryLocationId: fields.deliveryLocationId.required(),
			deliveryDate: fields.deliveryDate.required(),
		});
	}
	if (type === NewLegType.Blank) {
		schema = schema.shape({
			notes: fields.notes.required(),
		});
	}
	return [schema, (type: number) => setType(Number(type))] as const;
};

type Leg = InferType<ReturnType<typeof useLegFormSchema>[0]>;
type LegFormProps = {
	defaultValues?: Partial<Leg>;
	lLegTypes: LoadOptionsFn;
	lGoods: LoadOptionsFn;
	lLocations: LoadOptionsFn;
	lSubcontractors: LoadOptionsFn;
	lTruckTypes: LoadOptionsFn;
	lTrailerTypes: LoadOptionsFn;
	lDrivers: LoadOptionsFn;
	lTrucks: LoadOptionsFn;
	lTrailers: LoadOptionsFn;
	lBusinessUnits: LoadOptionsFn;
	lSupplierInvoiceTypes: LoadOptionsFn;
	lCurrencies: LoadOptionsFn;
	onSubmit: (data: Leg) => Promise<unknown>;
	onAddLocation?: (callback: (value: number) => void) => void;
};
export const LegForm = (x: LegFormProps) => {
	const [schema, setType] = useLegFormSchema(x.defaultValues?.legType);
	const form = useForm<Leg>({
		resolver: yupResolver(schema),
		defaultValues: x.defaultValues,
	});
	const type = form.watch("legType");
	const kind = legKindOf(type);
	useEffect(() => {
		setType(type);
		form.clearErrors();
	}, [type, setType, form.clearErrors]);
	return (
		<GenericForm schema={schema} form={form} onSubmit={x.onSubmit}>
			<DGrid>
				<ISelect n="legType" l={x.lLegTypes} />
			</DGrid>
			{kind && (
				<DFlex>
					<div style={{ width: "100%" }}>
						<IMultiSelect n="goodsIds" l={x.lGoods} />
						{kind === "storage" && (
							<>
								<ISelect n="storageLocation" l={x.lLocations} />
								<IDateTime n="storageStartDate" />
								<IDateTime n="storageEndDate" />
							</>
						)}
						{kind === "ferry" && (
							<>
								<IText n="ferryRoute" />
								<IDateTime n="ferrySailingDate" />
								<IText n="ferryReferenceNumber" />
							</>
						)}
						{kind === "clearCustoms" && (
							<>
								<IText n="ucr" />
								<IText n="mrn" />
								<IText n="t1" />
								<IText n="clearanceLocation" />
								<IDateTime n="clearanceDate" />
							</>
						)}
						{kind === "operation" && (
							<>
								<ISelect n="collectionLocationId" l={x.lLocations} />
								<IDateTime n="collectionDate" />
								<ISelect n="deliveryLocationId" l={x.lLocations} />
								<IDateTime n="deliveryDate" />
							</>
						)}
					</div>
					{kind !== "blank" && kind !== "storage" && (
						<div style={{ minWidth: "184px", width: "100%" }}>
							{kind === "operation" && (
								<>
									<ISelect n="truckTypeId" l={x.lTruckTypes} />
									<ISelect n="trailerTypeId" l={x.lTrailerTypes} />
								</>
							)}
							<ISelect n="driverId" l={x.lDrivers} />
							<ISelect n="truckId" l={x.lTrucks} />
							<ISelect n="trailerId" l={x.lTrailers} />
							<ISelect n="subcontractorId" l={x.lSubcontractors} />
							<ISelect n="transferBusinessUnitId" l={x.lBusinessUnits} />
						</div>
					)}
					<div style={{ width: "100%" }}>
						<DGrid columns="1fr .8fr">
							<INumber n="cost" step={0.01} />
							<ISelect n="currencyId" l={x.lCurrencies} />
						</DGrid>
						<IText n="supplierInvoiceNumber" />
						<ISelect n="supplierInvoice" l={x.lSupplierInvoiceTypes} />
						<IDateTime n="supplierInvoiceDate" />
						<ITextArea n="notes" />
					</div>
				</DFlex>
			)}
		</GenericForm>
	);
};

/*
Leg type Storage:
Goods, Storage Location, Storage Start Date, Storage End Date
Cost, Currency, Supplier Invoice Number, Supplier Invoice Type, Supplier Invoice Date, Notes, Documents

Leg type Ferry:
Goods, Ferry Route, Ferry Sailing Date, Ferry Reference Number
Driver, Truck, Trailer, Subcontractor, Business Unit
Cost, Currency, Supplier Invoice Number, Supplier Invoice Type, Supplier Invoice Date, Notes, Documents

Leg type Customs:
Goods, UCR, MRN, T1, Clearance Location, Clearance Date
Driver, Truck, Trailer, Subcontractor, Business Unit
Cost, Currency, Supplier Invoice Number, Supplier Invoice Type, Supplier Invoice Date, Notes, Documents

Leg type Collection - Segement - Delivery - Collect Deliver - Container Pick Up - Container Drop Off - Container Goods Collection - Container Goods Delivery - Load and Lash - Devan:
Goods, Collection Location, Collection Date, Delivery Location, Delivery Date
Truck Type, Trailer Type, Driver, Truck, Trailer, Subcontractor, Business Unit
Cost, Currency, Supplier Invoice Number, Supplier Invoice Type, Supplier Invoice Date, Notes, Documents

Leg type Blank:
Goods
Cost, Currency, Supplier Invoice Number, Supplier Invoice Type, Supplier Invoice Date, Notes, Documents

Missing:
Storage Location, Storage Start Date, Storage End Date
Ferry Route, Ferry Sailing Date, Ferry Reference Number
UCR, MRN, T1, Clearance Location, Clearance Date

Kind of legs:
operation: Collection - Segement - Delivery - Collect Deliver - Container Pick Up - Container Drop Off - Container Goods Collection - Container Goods Delivery - Load and Lash - Devan
storage: Storage
ferry: Ferry
clearCustoms: Customs
blank: Blank
*/
